import * as React from "react";
import { useEffect, useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import "@fontsource/creepster";
import "@fontsource/berkshire-swash";
import { graphql } from "gatsby";

import GlobalStyles from "../styles/globalStyles";
import Game from "../components/game";
import Scoreboard from "../components/scoreboard";
import breakpoints from "../styles/breakpoints";
import { centered } from "./aag2";

export interface Game {
  name: string;
  isFinished: boolean;
  result: Clown[];
}

export interface Clown {
  name: string;
  points: number;
}

interface GameList {
  id: string;
  gameList: Game[];
}
interface QueryData {
  data: {
    allSanityGames: { nodes: GameList[] };
    allSanityClown: { nodes: Clown[] };
  };
}

const fadeIn = keyframes`
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}`;

const fadeInAndOut = keyframes`
     0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    }
    100% {
      opacity: 0;
    }
`;

const StyledMain = styled.main`
  background: #f3f3f3;
`;

const LandingSection = styled.section`
  position: relative;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: Helvetica, Arial, sans-serif;
  padding: 25px 0;
`;

const Headline = styled.div`
  color: #000;
  font-size: 12vw;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const RingContainer = styled.div`
  position: relative;
  height: 20%;

  @media ${breakpoints.tabletLandscape} {
    height: 45%;
  }

  .rings {
    max-height: 100%;
    position: relative;
    z-index: 10;
  }

  .face {
    position: absolute;
    width: 15%;
    opacity: 0;

    animation: ${fadeIn};
    animation-duration: 2000ms;
    animation-fill-mode: forwards;

    &--johan {
      left: 10%;
      top: 20%;
    }

    &--calle {
      top: 47%;
      left: 27%;
      animation-delay: 1000ms;
    }

    &--pontus {
      top: 12%;
      left: 41%;
      width: 18%;
      animation-delay: 2000ms;
    }

    &--robin {
      top: 47%;
      left: 59%;
      animation-delay: 3000ms;
    }

    &--linus {
      top: 10%;
      left: 76%;
      animation-delay: 4000ms;
    }
  }
`;

const GameSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 150px;
  margin-bottom: 300px;
  padding-top: 150px;
  position: relative;

  h2 {
    text-transform: uppercase;
  }

  > div {
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 29%;

    line-height: 1.5;

    padding: 10px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

    list-style: none;
  }

  > img {
    position: absolute;
    bottom: 0;
    left: 10vw;
    width: 300px;
  }
`;

const GreatestHits = styled.marquee`
  margin-top: 200px;

  img {
    margin-right: 200px;
  }

  .pontus {
    animation-delay: 6000ms;
  }

  .johan {
    animation-delay: 10000ms;
  }
  .linus {
    animation-delay: 14000ms;
  }
  .calle {
    animation-delay: 18000ms;
  }
`;

const IndexPage = ({ data }: QueryData) => {
  const [initiated, setInitiated] = useState(false);
  const [finishedGames, setFinishedGames] = useState([]);
  const [upcomingGames, setUpcomingGames] = useState([]);
  const games = data?.allSanityGames?.nodes[0].gameList;

  const clowns = data?.allSanityClown?.nodes;

  games.map((game) => (game.name = game.name.split(" - Åg 2024")[0]));
  const audioRef = useRef();

  useEffect(() => {
    setFinishedGames(games.filter((game) => game.isFinished));
    setUpcomingGames(games.filter((game) => !game.isFinished));
  }, []);

  return (
    <>
      <GlobalStyles />
      <StyledMain>
        <LandingSection>
          <Headline>ÅGLYMPICS</Headline>
          <RingContainer>
            <img className="rings" src="/rings.png" />
            <img className="face face--johan" src="/johan-os.png" />
            <img className="face face--calle" src="/calle-os.png" />
            <img className="face face--pontus" src="/pontus-os.png" />
            <img className="face face--robin" src="/robin-os.png" />
            <img className="face face--linus" src="/linus-os.png" />
          </RingContainer>
          <Headline>2024</Headline>
        </LandingSection>

        <GameSection>
          <div>
            <h2>Kommande spel</h2>
            {upcomingGames.map((game, index) => {
              return <Game game={game} key={`upcoming-${index}`} />;
            })}
          </div>

          <div>
            <h2>Avslutade spel</h2>
            {finishedGames.map((game, index) => {
              return <Game game={game} key={`finished-${index}`} />;
            })}
          </div>

          <div>
            <h2>Poängställning</h2>
            <Scoreboard games={finishedGames} clowns={clowns} />
          </div>

          <GreatestHits direction="right" behavior="alternate">
            <img className="robin" src="/robin-springer.png" />
            <img className="pontus" src="/pontus-horse.png" />
            <img className="johan" src="/johan-eddie.png" />
            <img className="linus" src="/linus-flagga.png" />
            <img className="calle" src="/calle-eld.png" />
            <img className="robin" src="/robin-springer.png" />
            <img className="pontus" src="/pontus-horse.png" />
            <img className="johan" src="/johan-eddie.png" />
            <img className="linus" src="/linus-flagga.png" />
            <img className="calle" src="/calle-eld.png" />
          </GreatestHits>
        </GameSection>
      </StyledMain>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query Aag2024Query {
    allSanityGames(filter: { title: { eq: "Game List Aag 2024" } }) {
      nodes {
        id
        gameList {
          name
          isFinished
          result {
            name
          }
        }
      }
    }
    allSanityClown {
      nodes {
        name
        points
      }
    }
  }
`;
