const deviceSizes = {
  mobile: 375,
  largeMobile: 600,
  tablet: 768,
  tabletLandscape: 1024,
  desktopLarge: 1700,
};

export default {
  mobile: `(min-width: ${deviceSizes.mobile}px)`,
  largeMobile: `(min-width: ${deviceSizes.largeMobile}px)`,
  tablet: `(min-width: ${deviceSizes.tablet}px)`,
  tabletLandscape: `(min-width: ${deviceSizes.tabletLandscape}px)`,
  desktopLarge: `(min-width: ${deviceSizes.desktopLarge}px)`,
};
