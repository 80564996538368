import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
  }

  h1 {
    line-height: 1;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;
